.digital-weight-display {
    font-family: "Digital-7 Mono", monospace;
    background-color: black;
    color: lime;
    padding: 10px 20px;
    border-radius: 8px;
    border: 2px solid lime;
    display: inline-block;
    text-align: center;
  }
  
  .cctv-container iframe {
    height: 300px;
    border-radius: 8px;
    border: none;
  }
  
  button.w-45 {
    width: 45%;
  }
  
  .shadow {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  