*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}
a{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
#signinmodel{
    background-color:rgba(255, 255, 255, 0.5); /* Adjust the alpha value to control transparency */
    /* box-shadow: 10px 100px 10px  white; */
  }
  
/* Add this to your CSS */
.navbar-nav .dropdown-menu {
    max-height: 300px; /* Limit the height of the dropdown */
    overflow-y: auto; /* Enable scrolling within the dropdown */
    z-index: 1050; /* Ensure it's above other content */
    background-color: #fff; /* Background for dropdown */
}

/* Adjust for small screens */
@media (max-width: 576px) {
  .navbar-collapse {
    max-height: 350px;
    overflow-y: auto;
  }
  
  .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
    white-space: nowrap; /* Prevent items from wrapping */
  }

  /* Ensure dropdown toggles work on mobile */
  .navbar-nav .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
}

/* Adjust z-index to ensure dropdown shows on top */
.navbar-collapse {
  z-index: 1001;
}
